@tailwind base;

@tailwind components;

.btn{
  @apply inline-block bg-brand-green-500 text-white font-bold p-6;
}
.btn:hover{
  @apply bg-brand-green-600;
}

@tailwind utilities;
